<template>
  <div class="cr-todo-workspace-main">
    <v-parallax
      dark
      height="96"
      src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"
    />
    <Avatar
      nudgeLeft="50"
      nudgeTop="-10"
      avatarCls="cr-workspace-avatar"
      :workspace="workspace"
    />
    <div class="d-flex align-center mt-4 mb-10">
      <div
        class="d-flex justify-space-between"
        style="margin-left: 200px; margin-right: 60px; width: 100%;"
      >
        <Title :workspace="workspace" />

        <v-btn depressed height="42" width="200" color="primary" @click="click">
          {{ btnTitle }}
        </v-btn>
      </div>
    </div>

    <Content />
  </div>
</template>

<style lang="scss" scoped>
.cr-todo-workspace-main::v-deep {
  height: 100%;

  .cr-workspace-avatar {
    position: absolute;
    top: 74px;
    left: 80px;

    &.cr-workspace-avatar-emoji {
      span {
        font-size: 70px !important;
      }
    }
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";

import Title from "./workspace/Title.vue";
import Avatar from "./workspace/Avatar.vue";
import Content from "./workspace/Content.vue";

export default {
  components: { Title, Avatar, Content },
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    ...mapGetters("todoWorkspace", [
      "allWorkspaces",
      "myWorkspaces",
      "currentWorkspace"
    ]),
    ...mapGetters("todoRoute", ["routeWorkspaceId"]),
    workspace() {
      const [workspace] = this.allWorkspaces.filter(
        w => w.id == this.routeWorkspaceId
      );
      return workspace || { title: "" };
    },
    possessionWorkspace() {
      return this.currentWorkspace.ownerId == this.getUserInfo.id;
    },
    joinedWorkspace() {
      return (
        this.myWorkspaces.findIndex(w => w.id == this.currentWorkspace.id) > -1
      );
    },
    btnTitle() {
      if (this.possessionWorkspace) {
        return "워크스페이스 삭제";
      }

      if (this.joinedWorkspace) {
        return "워크스페이스 팔로우 취소";
      }

      return "워크스페이스 팔로우";
    }
  },
  methods: {
    ...mapActions("confirm", ["confirm", "disagree", "agree"]),
    ...mapActions("todoWorkspace", [
      "followWorkspace",
      "unfollowWorkspace",
      "deleteWorkspace"
    ]),
    click() {
      const { id: workspaceId, title } = this.workspace;
      if (!workspaceId || workspaceId <= 0) return;

      if (this.possessionWorkspace) {
        this.confirm({
          message: `${title} 워크스페이스를 삭제 하시겠습니까?`,
          callback: () => {
            this.deleteWorkspace(workspaceId);
          },
          showCloseBtn: true
        });
        return;
      }

      if (this.joinedWorkspace) {
        this.confirm({
          message: `${title} 워크스페이스 팔로우를 취소하시겠습니까?`,
          callback: () => {
            this.unfollowWorkspace({ workspaceId, title });
          },
          showCloseBtn: true
        });
        return;
      }

      this.followWorkspace({ workspaceId, title });
    }
  }
};
</script>
