<template>
  <div style="height: 100%; padding: 10px 80px;">
    <v-tabs v-model="tab">
      <v-tab>팔로워</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-list max-height="600" style="overflow-y: scroll;">
          <template v-for="(user, idx) in workspaceUsers">
            <v-list-item
              :key="`${idx}_${user.id}`"
              style="height: 60px; max-width: 500px;"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    `${user.accountName} &nbsp;&nbsp;  &lt;${user.username}&gt;`
                  }}
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-action-text>
                  <v-tooltip
                    v-if="getUserInfo.id == ownerId || user.id == ownerId"
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="delegate(user)">
                        <v-icon :color="user.id == ownerId ? 'yellow' : ''">
                          mdi-crown
                        </v-icon>
                      </v-btn>
                    </template>
                    {{
                      user.id == ownerId
                        ? "이 워크스페이스의 소유자"
                        : "이 유저를 소유자로 설정"
                    }}
                  </v-tooltip>
                  <v-tooltip
                    v-if="getUserInfo.id == ownerId && user.id !== ownerId"
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="unfollow(user)">
                        <v-icon>
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </template>
                    이 유저의 팔로우 끊기
                  </v-tooltip>
                </v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return { tab: undefined };
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    ...mapGetters("todoWorkspace", ["workspaceUsers", "currentWorkspace"]),
    ownerId() {
      return this.currentWorkspace.ownerId ?? 0;
    }
  },
  methods: {
    ...mapActions("todoWorkspace", [
      "unfollowWorkspaceUser",
      "delegateWorkspaceOwner"
    ]),
    unfollow({ id: userId, accountName: name }) {
      const { id: workspaceId } = this.currentWorkspace;
      this.unfollowWorkspaceUser({ name, workspaceId, userId });
    },
    delegate({ id: userId, accountName: name }) {
      if (this.ownerId == userId) return;
      const { id: workspaceId } = this.currentWorkspace;
      this.delegateWorkspaceOwner({ name, workspaceId, userId });
    }
  }
};
</script>
