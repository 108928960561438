<template>
  <div class="cr-text-field-wrapper" :class="editable ? 'editable' : ''">
    <v-tooltip v-if="!editable" top>
      <template v-slot:activator="{ on }">
        <div v-on="on" class="cr-title text-h5" @click.stop="changeEditable">
          <span class="text-truncate">{{ inputValue }}</span>
        </div>
      </template>
      클릭하여 편집하기
    </v-tooltip>

    <v-text-field
      v-else
      ref="textRef"
      dense
      outlined
      hide-details
      class="text-h5"
      :loading="loading"
      :disabled="loading"
      @focus="inputFocus"
      @blur="inputBlur"
      @keydown.enter="enter"
      @keydown.esc="$refs.textRef.blur()"
      :value="inputValue"
    ></v-text-field>
  </div>
</template>

<style lang="scss" scoped>
.cr-text-field-wrapper {
  width: 100%;
  max-width: 700px;
  min-width: 0px;
  &.editable {
    margin-left: 7px;
  }

  .cr-title {
    display: inline-flex;
    max-width: 700px;
    min-width: 0px;
    font-size: 16px;
    padding: 7px 5px 3px 5px;
    margin: 0px 8px;
    cursor: text;
    &:hover {
      border: thin solid rgba(0, 0, 0, 0.12);
      padding: 6px 5px 2px 5px;
      margin: 0px 7px;
    }
  }

  .v-input.v-text-field::v-deep {
    max-width: 700px;
    .v-input__control {
      .v-input__slot {
        padding: 0px 5px;
        fieldset {
          border: none;
        }
      }
    }
  }
}
</style>

<script>
import { mapActions } from "vuex";
import { isBlank } from "@/commons/utils/validation";

export default {
  props: {
    workspace: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      editable: false
    };
  },
  computed: {
    inputValue() {
      return this.workspace.title ?? "";
    }
  },
  methods: {
    ...mapActions("todoWorkspace", ["updateWorkspace"]),
    changeEditable() {
      this.editable = true;
      this.$nextTick(() => {
        this.$refs.textRef?.focus();
      });
    },
    inputFocus() {
      this.$el.style.border = "1px solid var(--v-primary-base)";
    },
    inputBlur() {
      this.$el.style.border = "";
      this.editable = false;
      this.$refs.textRef?.reset();
    },
    async enter(e) {
      const title = e.target.value.trim();
      if (isBlank(title) || this.inputValue == title) {
        this.$refs.textRef?.blur();
        return;
      }

      this.loading = true;
      const {
        workspace: { id: workspaceId, emoji }
      } = this;
      await this.updateWorkspace({ workspaceId, title, useEmoji: !!emoji });

      this.loading = false;
      this.$refs.textRef?.reset();
      this.$refs.textRef?.blur();
    }
  }
};
</script>
